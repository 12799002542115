.section-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  margin: -25px -25px 25px -25px;

  background-color: #D3E5E3;

  button {
    background-color: transparent;
    border: 0;
  }

  .filter-wrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;
    margin-right: 15px;
  }

  .filter-search {
    flex-grow: 1;
    max-width: 600px;
    margin-left: 30px;
    background-color: $gray-7;
    border-radius: 4px;

    label {
      display: flex;
      align-items: center;

      input {
        padding: 10px;
        width: 100%;

        font-size: 15px;

        background-color: transparent;
        border: 0;

        @include placeholder {
          color: $gray-8;
        }
      }

      svg {
        margin-right: 10px;

        font-size: 15px;
        color: $gray-5;
      }
    }
  }

  .pagination-wrapper {
    .pagination {
      display: flex;
      padding: 0;
      list-style: none;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 31px;
      height: 34px;

      border: 1px solid $brand-color;
      border-left: 0;

      font-size: 14px;

      &:first-of-type {
        border-left: 1px solid $brand-color;

        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-of-type {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &.active {
        background-color: $brand-color;
        color: white;

        .page-link {
          color: $white;
        }
      }

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        color: $brand-color;
      }
    }

    .page-link {
      display: flex;

      font-size: 14px;
      color: $brand-color;

      svg {
        height: 15px;
      }
    }
  }
}