.page-get-started {
  .banner {
    margin-bottom: 60px;
  }
  .steps {
    background-color: transparent;
    &__item {
      display: flex;
      border-radius: 4px;
      overflow: hidden;

      margin-bottom: 20px;

      &.active-steps {
        button {
          &[disabled] {
            opacity: 0.5;
          }
          opacity: 1;
        }
      }

      button {
        margin-left: auto;
      }
    }
    &__number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 58px;

      font-family: $font-ptsans;
      font-size: 22px;
      color: white;

      background-color: $brand-color;
    }
    &__description {
    }

    .wrapper {
      display: flex;
      flex-grow: 1;
      align-items: center;
      padding: 7px 11px 7px 20px;
      background-color: white;
    }
    button {
      opacity: 0.4;
    }
  }

  .btn-purple {
    width: 100%;
    padding: 17px;
    margin-bottom: 20px;
  }
}
