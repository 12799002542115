.branding,
.email {
  .configuration-wrapper {
    padding-bottom: 80px;

    .branding,
    .email {
      .form-wrapper.v2 {
        margin-top: 0;
      }
    }
  }

  .duo-wrapper {
    display: flex;
    justify-content: space-between;

    .left {
      width: 45%;
    }

    .right {
      width: 52%;
    }
  }

  button[type='submit'].btn-purple {
    position: absolute;
    bottom: 49px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }

  .form-wrapper.v2 {
    .input-wrapper {
      margin-bottom: 20px;
    }
  }
}

.email {
  .configuration-wrapper {
    padding-bottom: 0px;
  }

  .Dropdown-arrow {
    display: none;
  }

  .btn-upload {
    margin-left: 9px;
  }
}

// TODO: needs to be on separete file
//standalone component.
.preview-onboarding {
  max-width: 1194px;
  margin: 0 auto;
  overflow: hidden;

  box-shadow: 0 0 90px #a4a4a44a;
  transform-origin: top;
  transform: scale(0.8) translateY(40px);
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;

  .action {
    &__title {
      font-size: 20px;
      margin-bottom: 15px;
    }
    &__description {
      font-size: 18px;
    }
    &__label {
    }
    &.input-wrapper {
      display: block;
      background-color: inherit;
      label,
      input,
      p,
      span,
      h3 {
        color: inherit;
        font-family: inherit;
      }

      input {
        margin-left: 80px;
      }
    }
  }

  .img-header {
    img {
      width: 100%;
    }
  }
  .customer-header {
    flex-flow: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    margin-top: -8px;
    &__wrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 700px;
      text-align: center;
    }
    &__logo {
      max-width: 500px;
      max-height: 150px;
      margin-bottom: 30px;
    }
    &__title {
      margin-bottom: 30px;
      width: 100%;
    }

    &__title-description {
    }
  }
  .customer-body {
    padding-top: 50px;
    .button-wrapper {
      padding: 100px 0;
      button {
        opacity: 1;
      }
    }
    .btn {
      margin: 0 auto;

      padding: 20px 70px;

      font-size: 20px;
    }
  }
  .form-wrapper.v2 {
    margin-top: 0;
    max-width: 80%;
  }
  .action {
    &__title {
      font-size: 20px;
      margin-bottom: 15px;
    }
    &__description {
      font-size: 18px;
      margin-bottom: 15px;
    }
    &__label {
    }
    &.input-wrapper {
      display: block;
      background-color: inherit;
      label,
      input,
      p,
      h3 {
        color: inherit;
        font-family: inherit;
      }

      input {
        margin-left: 80px;
      }
    }
  }

  &.preview-webform {
    border-radius: 0;

    .customer-body {
      .button-wrapper {
        padding: 40px 0;
      }
    }
  }
}

.preview-email {
  padding-bottom: 50px;
  position: relative;
  min-height: 740px;
  transform-origin: top;
  transform: scale(0.6) translateY(60px);
  max-width: 1191px;
  margin: 0 auto;
  box-shadow: 0 0 68px #80808030;
  border-radius: 15px;
  overflow: hidden;

  img{
    max-width: 100%;
    height: auto;
  }

  .content {
    position: absolute;
    top: 30%;
    left: 22%;
    max-width: 53%;

    overflow: hidden;

    &__banner {
      display: flex;
      justify-content: center;
      flex-flow: column;
      flex-wrap: wrap;
      padding: 50px 0;
      img {
        max-height: 120px;
        object-fit: contain;
      }
      h2 {
        text-align: center;
        font-size: 28px;
      }
    }

    &__body {
      .container {
        max-width: 80%;
        margin: 0 auto;
        padding: 30px 0;
      }
    }
  }
}