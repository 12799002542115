.edit-web-form {
  .form-wrapper {
    margin-top: 0;
  }

  .transparent-input{
    border-bottom: none;
  }

  .preview-webform {
    .customer-header {
      &__wrapper {
        flex-direction: column;
        align-items: center;
      }

      &__title, 
      &__title-description {
        width: auto;
        border-bottom: 1px solid $black;
        border-bottom-style: dotted;
      }

      &__title {
        &:first-of-type {
          margin-bottom: 0;
        }

        font-size: 25px;
        font-weight: 700;
      }
    }
  }

  .toggler-wrapper {
    .toggler-button {
      border-radius: 4px;

      span {
        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &:nth-of-type(2) {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
}