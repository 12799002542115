.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;

  .modal-main {
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      color: #4ea699;
      .modal-title {
        font-size: 20px;
        font-weight: bold;
        font-family: $font-ptserif;
      }
      .modal-close {
        border: 0;
        background: none;
        font-size: 20px;
        svg {
          color: #4ea699;
        }
      }
    }
    .modal-body {
      .modal-form {
        &--group {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          justify-content: space-between;
          span {
            max-width: -webkit-max-content;
          }
          &:nth-child(2) {
            flex: 1;
            .builder-input {
              width: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
    .modal-footer {
      margin-top: 20px;
      .btn {
        display: flex;
        align-items: center;
        justify-items: center;
        width: 100%;
        padding: 0 25px;
      }
    }
  }
}

.modal-main {
  position: fixed;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 5px;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 50px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
