.not-found {
  .content-wrapper {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 140px);
  }
  
  h2 {
    font-family: $font-ptserif;
    font-size: 36px;
    color: $white;
    text-align: center;
  }

  .btn-purple {
    margin-top: 20px;
  }
}

@include respond-to (md-down) {
  .not-found {
    .content-wrapper {
      height: calc(100vh - 100px);
    }
  }
}

@include respond-to (sm-down) {
  .not-found {
    h2 {
      font-size: 30px;
    }
  }
}