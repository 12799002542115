//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;

$font-ptsans: "PT Sans";
$font-ptserif: 'PT Serif';
$font-roboto: 'Roboto';
$font-ptmono: 'PT Mono';

/// Code (monospace) font family
/// @type List
$code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Monaco", monospace !default;

/// Copy text color
/// @type Color
$text-color: #222 !default;

/// Main brand color
/// @type Color
$brand-color: #4ea699 !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: "../assets/" !default;

// Other Variables

$white: #ffffff;
$black: #000000;
$black-2:#252422;

$primary: #c4d3d1;

$maroon: #d22630;

$purple: #7e78d2;
$seal-brown: #1c0b19;
$gray: #5b5851;
$gray-2: #837f76;
$gray-3: #e2e2e2;
$gray-4: #f5f5f5;
$gray-5: #5a5751;
$gray-6: #e5e5e4;
$gray-7: #c1bfbf33;
$gray-8: #a8b1bc;
$gray-9: #d8d8d8;
$gray-10: #1C0B19;
$gold: #B7974F;
$titan-white: #f7f7ff;
