.register-page {
  .title-2 {
    font: normal normal bold 24px/25px PT Serif;
    margin-bottom: 15px;
  }
  p {
    font: normal normal normal 16px/25px PT Sans;
  }

  .space-top {
    margin-top: 70px;
  }

  p,
  h1,
  h2 {
    color: $seal-brown;
  }

  .banner {
    margin-bottom: 45px;
    text-align: center;
    background-color: $primary;
    padding: 60px;

    &.duplicate-email {
      margin-bottom: 0;
    }

    &__logo {
      max-width: 205px;
      margin-bottom: 30px;
    }

    &__title {
      max-width: 646px;
      margin: 0 auto;
      margin-bottom: 30px;

      font: normal normal bold 36px/42px PT Serif;
    }

    &__description {
      max-width: 832px;
      margin: 0 auto;

      font: normal normal normal 16px/25px PT Sans;
    }
  }

  .form-wrapper {
    margin-top: 50px;
    max-width: none;
    input {
      padding: 0 25px;
      min-height: 58px;
      max-width: 76%;
      margin-left: auto;
      background-color: white;
      border-radius: 5px;
      border: 1px solid $gray-3;
    }
    .input-wrapper {
      padding-left: 0;
      border: none;
    }
    label {
      display: flex;
      align-items: center;

      color: #252422;
      font: normal normal bold 18px/31px PT Sans;
    }
  }

  .content-wrapper {
    max-width: 930px;
    margin: 0 auto;
    margin-bottom: 60px;

    &.duplicate-email {
      display: flex;
      align-items: center;
      margin-top: 60px;
      margin-bottom: 0;
      height: 100%;
    }
  }

  button {
    width: 100%;
    border: none;
    min-height: 75px;

    font: normal normal bold 24px/25px PT Sans;
  }

  .subscription-wrapper {
    padding: 50px 0;
    text-align: center;
    background-color: $gray;

    &.duplicate-email {
      position: fixed;
      bottom: 0;

      width: 100%;
    }

    p {
      color: white;
      font: normal normal normal 20px PT Sans;
    }
    img {
      max-width: 205px;
    }
  }

  .notification {
    margin-top: 60px;
    text-align: center;
    font-weight: bold;
    &-success {
      color: green;
    }

    &-error {
      color: red;
    }
  }
}

@include respond-to(md-down) {
  .register-page {
    .form-wrapper {
      .input-wrapper {
        padding: 0;
      }
    }
  }
}

@include respond-to(sm-down) {
  .register-page {
    .banner {
      padding: 60px 0;

      &__title {
        font-size: 26px;
        line-height: 1.2;
      }
    }

    .form-wrapper {
      margin-top: 20px;

      label {
        flex-direction: column;
        align-items: flex-start;
      }

      input {
        max-width: 100%;
        min-height: 48px;
      }
    }

    button {
      min-height: 62px;

      font-size: 16px;
    }

    .space-top {
      margin-top: 40px;
    }

    .content-wrapper {
      &.duplicate-email {
        margin-bottom: 60px;
      }
    }

    .subscription-wrapper {
      &.duplicate-email {
        position: unset;
      }
    }
  }
}
