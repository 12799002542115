*,
*:before,
*:after {
  margin: 0;
  box-sizing: border-box;
}

a,
button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.btn,
a {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;

  @include on-event {
    outline: 0;
    box-shadow: none;
  }
}

body {
  overflow-x: hidden;
}

/**
 * Basic styles for input fields
 */
input[type="email"],
input[type="password"],
input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-focus-ring-color: none;
  outline: none;

  @include on-event {
    box-shadow: none;
  }
}

input,
select,
button,
textarea {
  outline: none;
  @include appearance(none);

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    @include appearance(none);
  }
}

.container {
  padding: 0 15px;
  margin: 0 auto;
  max-width: 100%;

  @include respond-to(xl) {
    width: 1370px;
  }

  @include respond-to(lg) {
    width: 960px;
  }

  @include respond-to(md) {
    width: 720px;
  }

  @include respond-to(sm) {
    width: 540px;
  }

  @include respond-to(xs) {
    width: 100%;
  }
}

.toggle-menu {
  position: relative;
  top: 0;
  left: 5px;

  float: right;
  width: 36px;
  height: 28px;

  line-height: 1;

  background-color: transparent;
  border: 0;
  border-radius: 0;

  @include respond-to(lg-up) {
    display: none;
  }

  @include respond-to(md-down) {
    &.is-close {
      display: none;
    }

    &.is-open {
      display: block;
    }
  }

  .icon-bar {
    display: block;
    width: 25px;
    height: 3px;

    background: #ffffff;
    box-shadow: none;

    transition: 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);

    -ms-box-shadow: none;
    -o-box-shadow: none;
  }

  .icon-bar + .icon-bar {
    margin-top: 5px;
  }
}

.hidden {
  display: none !important;
}
