.page-my-account {
  .form-wrapper {
    margin-top: 20px;
    max-width: none;

    input {
      padding: 0 25px;
      min-height: 58px;
      max-width: none;

      background-color: white;
      border-radius: 5px;
      border: 1px solid $gray-3;

      &.input-error {
        border-color: $maroon;
      }
    }

    .input-wrapper {
      padding: 0;

      border: none;

      label {
        span {
          min-width: 157px;
        }
      }

      .error-text {
        left: unset;
        right: 0;

        background-color: #d22630;
      }

      &.input-error {
        input {
          border-color: $maroon;
        }
      }

      &:first-of-type {
        input {
          &:first-of-type {
            margin-right: 25px;
          }
        }
      }
    }

    label {
      display: flex;
      align-items: center;

      color: #252422;
      font: normal normal bold 18px/31px PT Sans;
    }
  }
  
  button[type="submit"] {
    margin-top: 20px;
    min-height: 51px;

    font-family: $font-ptsans;
    font-weight: normal;
    font-size: 18px;
  }
}
