.toggler-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .toggler-button {
    display: flex;
    align-items: center;

    .check {
      color: $brand-color;
    }

    .times {
      color: $maroon;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 58px;
      border: 1px solid $gray-3;
      
      background-color: $white;
      height: 58px;

      cursor: pointer;

      &:not(.active-btn) {
        background-color: $gray-3;
        color: $gray;
      }

      img {
        max-width: 18px;
      }
    }
  }

  .title {
    margin-right: 30px;

    color: #252422;
    font: normal normal bold 18px/31px PT Sans;
  }

  &:first-of-type {
    margin-right: 20px;
  }
}