.form-wrapper {
  max-width: 637px;
  margin: 0 auto;

  &.v2 {
    margin-top: 50px;
    max-width: none;

    

    button[type="submit"] {
      min-height: 43px;

      font: normal normal bold 16px $font-ptsans;
      letter-spacing: 0px;
    }

    .input-wrapper {
      padding: 0;
      border: none;
      margin-bottom: 12px;

      .sketch-picker {
        input {
          min-height: auto;
        }
      }
      .Dropdown-control {
        display: flex;
        align-items: center;
      }

      input,
      .Dropdown-control,
      select,
      div[id^="font-picker"] .dropdown-button {
        padding: 0 25px;
        min-height: 58px;
        max-width: none;

        font: normal normal normal 16px $font-ptsans;

        background-color: white;
        border-radius: 5px;
        border: 1px solid $gray-3;

        // &.input-error {
        //   border-color: $maroon;
        // }
      }
      label,
      .button-wrapper {
        span {
          min-width: 111px;
          font-weight: bold;
        }
      }

      .error-text {
        left: unset;
        right: 0;
        background-color: #d22630;
      }
      &.input-error {
        border: none;
        input,
        select {
          border-color: $maroon;
        }
      }
    }

    label,
    .button-wrapper {
      display: flex;
      align-items: center;

      color: #252422;
      font: normal normal bold 18px/31px PT Sans;
    }

    label.inline-input-wrapper{
     display: block;   
   
    }
  }

  .inline-with-text {
    padding: 0;

    font-family: inherit;
    text-align: center;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;

    background: transparent;
    border: none;
  }

  div[id^="font-picker"] {
    box-shadow: none;
    width: 100%;

    ul {
      box-shadow: none;
      background: rgb(196, 196, 196);
      // border: 1px solid $gray-3;
    }
  }
  .form-footer {
    text-align: center;
  }

  .input-wrapper {
    position: relative;

    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 25px;
    min-height: 58px;

    background-color: white;
    border-radius: 5px;
    border: 1px solid $gray-3;
    &.with-icon {
      position: relative;
      .checker-icon {
        position: absolute;
        right: 60px;
        // transform: scale(0.6);
        transform-origin: top;
        top: 9px;

        .css-u3106q,
        .css-y9jyby {
          height: 40px;
          width: 40px;
        }
        svg {
          position: absolute;
          right: -43px;
          top: 11px;

          font-size: 21px;
          &[data-icon="check"] {
            color: $brand-color;
          }
          &[data-icon="times"] {
            color: $maroon;
          }
        }
      }
    }

    &.input-error {
      border: 2px solid $maroon;
      input,
      select,
      label {
        color: #d22630;
      }
      .error-text {
        color: white;
      }
    }

    input,
    select,
    [id^="font-picker"],
    .palette-wrapper,
    .Dropdown-root {
      flex-grow: 1;
      // margin-left: 20px;

      border: none;
      background: transparent;
      color: $gray-10;
      font: normal normal normal 18px/31px PT Sans;
    }

    .upload {
      width: 100%;
      height: 49px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    label,
    .button-wrapper {
      display: flex;
      align-items: center;
      // min-width: 20%;
      width: 100%;

      color: $seal-brown;
      font: normal normal normal 18px/31px PT Sans;

      span {
        min-width: 115px;
        // min-width: 108px;
      }
    }
    .error-text {
      position: absolute;
      min-width: 202px;
      left: 97%;

      padding: 8px 10px 8px;

      font: normal normal normal 18px/31px PT Sans;
      text-align: right;

      background-color: $maroon;
      &:before {
        content: "";
        position: absolute;
        right: 100%;
        top: 0;

        width: 0px;
        height: 0px;

        border-top: 23.4px solid transparent;
        border-bottom: 23.4px solid transparent;
        border-right: 13px solid $maroon;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  button[type="submit"] {
    position: relative;
    width: 100%;
    min-height: 75px;

    font: normal normal bold 24px/25px PT Sans;
    color: white;
    letter-spacing: 0;

    > span.css-0 {
      // position: absolute;
      // right: 22px;
    }
  }

  .forgot {
    margin: 30px 0;
    display: inline-block;

    text-decoration: underline;
    color: white;
    font: normal normal normal 18px/31px PT Sans;
  }
}

@include respond-to(md-down) {
  .form-wrapper {
    max-width: 100%;

    .input-wrapper {
      padding: 0 15px;
      margin-bottom: 20px;
      min-height: 48px;

      input {
        width: 100%;

        font-size: 16px;
      }
    }

    button[type="submit"] {
      min-height: 62px;

      font-size: 16px;
    }

    .error-text {
      display: none;
    }
  }
}
