.page-my-subscription {
  .banner {
    margin-bottom: 20px;
  }
  .cancel-account {
    .tr {
      .td:first-child {
        width: 100%;
        max-width: none;

      }
    }
  }

  .description{
    max-width: 65%;
  }
}
