.dashboard {
  section {
    &.no-padding {
      padding: 0;
    }
  }

  .modal-first-action {
    .content-wrapper {
      flex-direction: column;
      align-items: flex-start;

      h3 {
        font-family: $font-ptserif;
        color: $brand-color;
      }

      .options__card {
        margin-right: 2.6%;
        margin-bottom: 15px;
        width: 23%;

        border-radius: 4px;
        box-shadow: 0px 0px 10px #00000029;

        &:nth-of-type(4n + 4) {
          margin-right: 0;
        }
      }

      .btn-green {
        position: unset;
      }
    }

    .cards {
      display: flex;
      flex-wrap: wrap;

      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 70px;
        margin-bottom: 15px;
        width: 100%;

        background-color: $brand-color;

        cursor: pointer;

        p {
          font-size: 16px;
          font-family: $font-ptserif;
          color: $white;
        }

        svg {
          font-size: 25px;
          color: $white;
        }

        .dollar-icon {
          position: absolute;
          left: -1px;  

          padding: 17px 21px;

          font-size: 16px;
          font-family: $font-roboto;
          font-weight: 700;
          color: $brand-color;

          background-color: $white;
        }
      }
    }

    .actions {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      cursor: pointer;
    }

    .form-wrapper {
      &.v2 {
        margin-top: 0;

        button[type="submit"] {
          position: unset;

          margin-top: 50px;
        }

        section {
          padding-left: 0;
          padding-right: 0;
        }

        .input-wrapper {
          &:first-of-type {
            margin-top: 12px;
          }
        }
        
        .colorize-div {
          display: flex;
          padding: 20px;
          margin-bottom: 20px;
          border-radius: 4px;
      
          .text-wrapper {
            display: flex;
            align-items: center;
      
            p {
              display: inline-block;
      
              font-family: $font-roboto;
              font-size: 16px;
      
              border-bottom: 1px solid #000;
              border-bottom-style: dotted;
            }
      
            svg {
              padding: 2px;
              margin-right: 10px;
      
              font-size: 20px;
              color: #f0b922;
      
              border: 2px solid #f0b922;
              border-radius: 100%;
            }
          }
        }

        .connect-wrapper {
          display: flex;
          flex-direction: column;
          position: unset;

          .section-title {
            margin-bottom: 30px;
          }

          button {
            position: unset;
          }
        }
      }
    }
  }
}
