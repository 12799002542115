.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &__card {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 150px;

    text-align: center;
    
    border-radius: 4px;
    background: white;
    border: 0;

    &:nth-of-type(4) {
      margin-right: 0;
    }

    p {
      padding-bottom: 15px;

      font: 700 18px $font-ptserif;
      color: $brand-color;
    }
  }

  &__link {
    margin-right: 2.5%;
    margin-bottom: 20px;
    width: 23%;
    // max-width: 232px;

    &:nth-of-type(4) {
      margin-right: 0;
    }
  }
}
.Toastify {
  &__toast-container {
    width: 399px;
  }
  &__toast {
    display: flex;
    align-items: center;
    &--success {
      background: white;
      color: $brand-color;
      font: 600 20px $font-ptserif;
      padding: 20px;
    }
  }
  &__close-button {
    position: absolute;
    right: 29px;
    bottom: 25px;

    color: $brand-color;
    color: #4ea699;

    transform: scale(1.5);
  }
}

.billsby-settings,
.chargebee-settings,
.actions,
.branding,
.email {
  .row {
    .extra {
      width: 37%;
      padding-left: 31px;
      font-size: 18px;
    }
  }

  .form-wrapper.v2 {
    .input-wrapper {
      margin-bottom: 0;

      &:nth-child(2) {
        margin-top: 20px;
      }
    }

    .btn-purple {
      display: flex;
      justify-content: center;
      padding: 15px;
      span {
        position: relative !important;
      }
    }
  }
}
