.sidebar-wrapper {
  display: flex;
  flex-flow: column;
  min-height: 100vh;

  svg {
    color: $brand-color;
  }

  font-family: $font-ptsans;
  font-size: 16px;

  .logo-wrapper {
    padding: 15px 18px;
    
    background-color: $brand-color;

    img {
      width: 100%;
      max-width: 146px;
    }
  }

  .logout {
    cursor: pointer;
    span {
      font-weight: 400;
      color: black;
    }
  }
  ul {
    padding-left: 18px;

    list-style: none;

    span {
      padding-left: 10px;

      color: $brand-color;
      font-weight: 700;
    }
  }
  .linklist {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    padding-top: 30px;
    padding-bottom: 30px;

    .is-disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    .svg-inline {
      &--fa {
        width: 16px;
      }
    }

    .nav-link:not(.is-active) {
      span {
        font-weight: 400;
        color: black;
      }
    }

    &__bottom {
      margin-top: auto;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  //ADMIN SIDEBAR
  &.sidebar-admin {
    ul {
      span {
        color: $gray-5;
        font-weight: 700;
      }
    }

    .logo-wrapper {
      background-color: $gray-5;
    }
    svg {
      color: $gray-5;
    }
  }
}
