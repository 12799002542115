%btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  color: $white;

  border-radius: 4px;
  border: none;

  transition: all 0.2s ease-in-out;

  &[disabled] {
    opacity: 0.5;
  }

  // > span {
  //   position: absolute;
  //   right: 22px;
  // }
}

.btn {
  @extend %btn;
}

.btn-purple {
  @extend %btn;

  padding: 10px;
  min-width: 267px;

  font-family: $font-ptsans;
  font-weight: bold;
  font-size: 16px;

  background-color: $purple;

  @include on-event {
    background-color: #7872c7;
  }
}

.btn-purple-inverted {
  @extend %btn;

  padding: 10px;
  min-width: 267px;

  font-family: $font-ptsans;
  font-size: 16px;
  font-weight: bold;
  color: #7e78d2 !important;
  
  background-color: #7e78d266;

  @include on-event {
    background-color: #7e78d266;
  }
}

.btn-disabled {
  pointer-events: none;
  background-color: #7e78d2;
  opacity: 0.4;
}

.btn-green {
  @extend %btn;

  padding: 10px;
  min-width: 267px;

  font-family: $font-ptsans;
  font-size: 16px;

  background-color: $brand-color;
}

.btn-red {
  @extend %btn;

  padding: 10px;
  min-width: 267px;

  font-family: $font-ptsans;
  font-size: 16px;

  background-color: $maroon;
}

.btn-gold {
  @extend %btn;

  padding: 10px;
  min-width: 267px;

  font-family: $font-ptsans;
  font-size: 16px;

  background-color: $gold;
}

.btn-unclickable {
  cursor: default;
}

.btn-icon-prefix {
  margin-right: 5px;
}

.btn-color {
  width: 36px;
  height: 46px;
  border: 1px solid $gray-3;
  border-radius: 4px;
}

.btn-upload {
  min-height: 56px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #7e78d2;
  background: #7e78d266;
  border-radius: 4px;
  font: normal normal bold 16px $font-ptsans;

  p {
    color: #7e78d2 !important;
  }
}

.btn-white {
  @extend %btn;
  
  font-size: 14px;
  color: $black;
  font-weight: 700;

  background-color: $white;
}
