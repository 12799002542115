header {
  position: fixed;
  top: -1px;
  left: 0;
  right: 0;
  z-index: 9999;

  padding: 15px 0;

  background-color: transparent;

  transition: all .3s ease;
  
  &.header-onscroll {
    background-color: $purple;

    .btn-purple {
      color: $purple;

      background-color: $white;
    }
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .logo-link {
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
    margin-right: 40px;

    .logo {
      width: 205px;
      max-width: 100%;
      height: auto;
    }
  }

  .menu-link-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .links-wrapper {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding: 0;
  }

  .sign-in-wrapper {
    position: relative;

    display: flex;
    align-items: center;

    .btn-holder {
      position: relative;
    }
  }
  
  .header-link {
    margin-right: 25px;
  
    font-size: 16px;
    font-weight: bold;
    color: $white;
  
    opacity: .8;

    &.is-active {
      opacity: 1;
    }
    
    @include on-event {
      opacity: 1;
    }
  }
}

@include respond-to(md-down) {
  header {
    top: 0;

    &.header-menu-open {
      background-color: $purple;
    }
    
    .burger-open {
      .icon-bar:nth-child(2) {
        opacity: 0;
      }
  
      .icon-bar:nth-child(1) {
        transform: rotate(225deg) translate(-3px, -5px);
      }
  
      .icon-bar:nth-child(3) {
        transform: rotate(-225deg) translate(-6px, 8px);
      }
    }

    .menu-wrapper {
      position: absolute;
      top: -675px;
      left: 0;

      display: block;
      width: 100%;
      padding-bottom: 25px;
      margin-left: 0;

      background-color: $purple;
      transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
      overflow: hidden;
    }

    .menu-open {
      top: 48px;
    }

    .menu-link-wrapper {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 15px;
      margin: auto;
      max-width: 720px;
    }

    .logo-link {
      .logo {
        width: 150px;
      }
    }

    .links-wrapper {
      align-items: flex-start;
      flex-direction: column;
      padding: 0;
      margin: 0;
      width: 100%;

      li {
        margin-bottom: 13px;
      }
    }

    .sign-in-wrapper {
      align-items: flex-start;
      flex-direction: column;
      width: 100%;

      .btn-holder {
        width: 100%;

        .btn-purple {
          margin-top: 15px;
          min-width: 100%;

          color: $purple;

          background-color: $white;

          @include on-event {
            color: $purple;
    
            background-color: $white;
          }
        }
      }
    }

    .signup-dropwdown {
      .signup-btn-holder {
        width: 100%;

        .btn-purple {
          @include on-event {
            color: $white;
    
            background-color: $purple;
          }
        }
      }
    }
  }
}