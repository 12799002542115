.page-customer-registration {
  .loader-wrapper {
    position: fixed;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    background-color: rgba(39, 39, 39, 0.945);
  }
  #trengo-web-widget {
    display: none;
  }
  .customer-header {
    flex-flow: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    &__wrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 700px;
      text-align: center;
    }
    &__logo {
      max-width: 500px;
      max-height: 150px;
      margin-bottom: 30px;
    }
    &__title {
      margin-bottom: 30px;
    }

    &__title-description {
    }
  }
  .customer-body {
    padding-top: 50px;
    min-height: 100vh;
    .button-wrapper {
      padding-top: 100px;
    }
    .btn {
      margin: 0 auto;

      padding: 20px 70px;

      font-size: 20px;
    }
  }
  .form-wrapper.v2 {
    margin-top: 0;
    max-width: 1400px;
    padding: 0 50px;
  }
  .action {
    &__title {
      font-size: 20px;
      margin-bottom: 15px;
    }
    &__description {
      font-size: 18px;
    }
    &__label {
    }
    &.input-wrapper {
      display: block;
      background-color: inherit;
      label,
      input,
      p,
      h3 {
        color: inherit;
        font-family: inherit;
      }

      input {
        margin-left: 80px;
      }
    }
  }
}
