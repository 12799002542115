.signup-dropdown {
  position: absolute;
  top: 52px;
  right: 0;
  left: 0;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
  margin: 0 auto;
  width: 100%;
  height: 222px;

  background-color: $white;
  border-radius: 4px;
  box-shadow: 0px 0px 4px #00000029;

  p {
    font-family: $font-ptserif;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .btn-purple {
    margin: 10px auto;
    width: 200px;
    min-width: 200px;

    color: $white !important;
    background-color: $purple !important;
  }

  &:before {
    content: '';
    position: absolute;
    top: -19px;
    left: 0;
    bottom: 0;
    right: 0;

    margin: 0 auto;
    width: 0; 
    height: 0; 

    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid $white;
  }
}

@include respond-to(md-down) {
  .signup-dropdown {
    top: 10px;
    bottom: 0;
    right: 0;
    left: 0;

    margin: 0 auto;

    p {
      font-size: 14px;
    }
  }

  .header {
    .signup-dropdown {
      position: relative;
    }
  }
}