.login-page {
  .page-title {
    font: normal normal bold 36px/42px PT Serif;
    margin-top: 80px;
    margin-bottom: 40px;
    text-align: center;
  }
}

@include respond-to(md-down) {
  .login-page {
    .page-title {
      font-size: 26px;
      line-height: 1;
    }
  }
}
