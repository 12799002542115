.wizard-container {
  .wizard-steps {
    display: flex;
    width: 100%;
    margin-top: 20px;
    flex-direction: column;
    font-size: 15px;

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      // margin-bottom: 15px;
      &__header {
        display: flex;
        align-items: center;
        height: 50px;
      }
      &__title {
        padding: 15px 20px;
        height: 100%;
        width: 100%;
        background: #edf6f5 0% 0% no-repeat padding-box;
        border-radius: 0px 4px 4px 0px;
      }

      &__body {
        margin-top: 15px;
        & > * {
          margin-bottom: 15px;
        }
        &__img-holder {
          display: flex;
          justify-content: center;
          img {
            width: 60%;
            object-fit: contain;
          }
        }

        .button-group {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin: 0;
          .btn-purple {
            width: 49%;
            svg {
              margin-right: 5px;
            }
            &.disabled-btn {
              opacity: 0.6;
            }
          }
        }

        .input-group-wrapper {
          display: flex;
          align-items: center;
          .input-label {
            font-weight: bold;
            margin-right: 35px;
            width: 120px;
          }
          .input-text-group {
            width: 100%;
            .input {
              background: #ffffff 0% 0% no-repeat padding-box;
              border: 1px solid #e2e2e2;
              border-radius: 4px;
              font-size: 15px;
              padding: 15px;
              &.full-width {
                width: 100%;
              }
            }
            .input-ext {
              margin-left: 10px;
            }
          }
        }

        h2 {
          font-family: $font-ptserif;
        }
      }
    }
    &__number {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #4ea699;
      border: 0;
      border-radius: 4px 0px 0px 4px;
      font-family: $font-ptserif;
      color: white;
      font-weight: bold;
    }
  }

  .config-wrapper {
    display: flex;
    justify-content: space-between;

    .btn-color {
      position: relative;
    }
    .picker-wrapper {
      position: relative;
    }
    .Dropdown-root {
      width: 100%;
      .Dropdown-control {
        padding: 8px 52px 8px 25px;
        font-size: 16px;
      }
      .Dropdown-arrow {
        display: none;
      }
    }
    .sketch-picker {
      // position: absolute;
      // left: 111%;
      // bottom: -346%;
      // z-index: 2;
    }

    &__left {
      position: relative;
      z-index: 1;

      flex-basis: 30%;

      .form-wrapper {
        &.v2 {
          margin-top: 25px !important;

          .input-wrapper {
            input,
            select {
              margin-left: 0;
            }
          }

          .btn-purple {
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              position: unset ;
            }
          }
        }
      }

      .btn-upload {
        cursor: pointer;

        p {
          color: #7e78d2;
        }

        .dropzone {
          pointer-events: none;
        }
      }
    }

    &__right {
  
      // padding-top: 5.6%;
      // height: 705px;
      width: 68%;
      flex-basis: 68%;


      &.config-onboarding {
        width: 60%;
        height: auto;
        flex-basis: 60%;
      }

      &.config-email {
        padding-top: 0;
        width: 56%;
        flex-basis: 56%;

        .preview-email{
          transform:none;

          img {
            max-width: 100%;
            height: auto;
          }
        }
       
        .config-header {
          padding: 25px 0;

          .email-desc {
            margin-top: -20px;

            font-weight: bold;
          }
        }

        .config-body {
          .btn-wrapper {
            margin: 15px auto 0;
          }

          .email-text {
            margin: 0 auto;
            width: 75%;

            font-size: 14px;
          }
        }

        .config-content {
          margin: 0 auto 0 25%;
          width: 50%;

          .image-wrapper {
            margin-bottom: 25px;
          }
        }
      }

      .img-wrapper {
        // position: absolute;
        width: 100%;
        max-height: 705px;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }

      .config-content {
        position: relative;

        margin: 0 auto;
        width: 89.5%;
        height: 100%;

        .image-wrapper {
          margin-bottom: 16px;

          img {
            width: 150px;
            height: auto;
          }
        }
      }

      .config-header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 25px;

        .header-heading {
          font-size: 14px;
          font-weight: bold;
          line-height: 18px;
          text-align: center;
        }

        .header-body {
          font-size: 10px;
        }
      }

      .config-body {
        padding: 28px 0;
        max-height: 76%;

        &__content {
          max-width: 75%;
          margin: 0 auto 20px;

          .title {
            font-weight: bold;
            font-size: 16px;
          }

          .desc {
            font-size: 10px;
          }

          .textfield-wrapper {
            display: flex;
            align-items: center;

            .textfield-title {
              font-size: 10px;
              font-weight: bold;
            }

            .textfield {
              display: flex;
              align-items: center;
              padding: 10px 8px;
              width: 283px;
              height: 17px;
              margin-left: 20px;

              font-size: 10px;

              background-color: $white;
              border: 1px solid #e2e2e2;
              border-radius: 2px;
            }
          }
        }

        .btn-wrapper {
          display: flex;
          margin: 45px auto 0;
          width: 75%;

          button {
            width: 100%;
            padding: 15px 0;
            margin: 0 auto;

            font-size: 14px;

            border-radius: 4px;

            pointer-events: none;
          }
        }
      }
    }

    .btn-purple {
      margin-top: 50px;
    }
  }

  .palette-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    // margin-left: 20px;

    &__single {
      display: flex;
      flex-flow: column;
      p {
        margin-top: 10px;
      }
    }

    &__colors {
      display: flex;
    }
  }

  .step-disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  .step-done {
    // pointer-events: none;
    cursor: pointer;
    z-index: 10;
    position: relative;

    .wizard-steps__content {
      margin-bottom: 15px;
    }

    &.collapse {
      margin-bottom: -70px;
    }
    &.less {
      opacity: 0.5;
      z-index: 5;
      position: relative;
    }
    &.lesser {
      opacity: 0.2;
      z-index: 3;
      position: relative;
    }

    .wizard-steps__number {
      background: #b7974f;
    }
  }

  .step-done:last-of-type {
    margin-bottom: 0px;
  }

  .btn-purple-inverted {
    width: 100%;
    padding: 17px;
    margin-bottom: 20px;
  }

  .error-msg {
    text-align: center;
    color: $maroon;
    font-weight: bold;
  }
}

// For Test it out modal
.switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 0;

  .toggle-switch {
    position: relative;

    display: inline-block;
    min-width: 340px;
    height: 90px;
  }

  .toggle-switch input[type='checkbox'] {
    display: none;
  }

  .toggle-switch .switch {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    border-radius: 100px;
    background-color: $white;
    box-shadow: 0px 0px 20px #00000029;
    border: 1px solid #e2e2e2;
    transition: background-color 0.2s ease;
    cursor: pointer;
  }

  .toggle-switch .switch::before {
    position: absolute;
    top: 8px;
    left: 13px;
    content: 'OFF';

    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;

    font-size: 22px;
    font-weight: bold;
    color: $white;

    background-color: #d22630;
    border-radius: 50%;
    box-shadow: 0px 0px 20px #00000069;
    transition: transform 0.3s ease;
    transform: translateX(238px);
  }

  .toggle-switch input[type='checkbox']:checked + .switch::before {
    content: 'ON';
    transform: translateX(0);
    // transform: translateX(238px);
    background-color: #4ea699;
  }

  .toggle-switch input[type='checkbox']:checked + .switch {
    background-color: $white;
  }
}

@media (min-width: 1200px)  and (max-width: 1299px) {
  .wizard-container {
    .config-wrapper__right {
      &.config-onboarding {
        flex-basis: 43vw;
      }
      
      &.config-email {
        flex-basis: 48%;

        .preview-email {
          .content {
            top: 7.5vw;
          }
        }
      }
    }
  }
}

@media (min-width: 1300px) and (max-width: 1399px) {
  .wizard-container {
    .config-wrapper__right {
      &.config-onboarding {
        flex-basis: 46vw;
      }
      
      &.config-email {
        flex-basis: 55%;
      }
    }
  }
}

@media (min-width: 1400px) and (max-width: 1450px) {
  .wizard-container {
    .config-wrapper__right {
      &.config-onboarding {
        flex-basis: 49vw;
      }
      
      &.config-email {
        flex-basis: 47vw;
      }
    }
  }
}

