.customers {
  .section-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    margin: 0;

    background-color: $gray-6;
  }

  button {
    background-color: transparent;
    border: 0;
  }

  .filter-wrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;
    margin-right: 15px;
  }

  .filter-search {
    flex-grow: 1;
    max-width: 600px;
    margin-left: 30px;
    background-color: $gray-7;
    border-radius: 4px;

    label {
      display: flex;
      align-items: center;

      input {
        padding: 10px;
        width: 100%;

        font-size: 15px;

        background-color: transparent;
        border: 0;

        @include placeholder {
          color: $gray-8;
        }
      }

      svg {
        margin-right: 10px;

        font-size: 15px;
        color: $gray-5;
      }
    }
  }

  .table-wrapper {
    padding: 30px;

    .table {
      background-color: $white;
      border: 1px solid $gray-9;
      border-radius: 4px;
    }

    .row-wrapper {
      display: flex;
      align-items: center;
      padding: 15px;

      border-bottom: 1px solid $gray-9;

      &:last-of-type {
        border: 0;
      }

      .column-wrapper {
        &:first-of-type {
          min-width: 22.5%;
        }

        &:not(:first-of-type) {
          min-width: 15.5%;
        }

        &:last-of-type {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .table-heading {
      font-family: $font-ptserif;
      font-size: 14px;
      font-weight: bold;
      color: $gray-5;
    }

    .table-info {
      font-size: 14px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 132px;
      height: 28px;

      font-size: 14px;
      color: $white;

      background-color: $gray-5;
      border-radius: 4px;

      svg {
        margin-right: 5px;
      }
    }
  }

  .pagination-wrapper {
    .pagination {
      display: flex;
      padding: 0;
      list-style: none;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 31px;
      height: 34px;

      border: 1px solid $gray-5;
      border-left: 0;

      font-size: 14px;

      &:first-of-type {
        border-left: 1px solid $gray-5;

        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-of-type {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &.active {
        background-color: $gray-5;
        color: white;

        .page-link {
          color: $white;
        }
      }

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        color: $gray-5;
      }
    }

    .page-link {
      display: flex;

      font-size: 14px;
      color: $gray-5;

      svg {
        height: 15px;
      }
    }
  }
}
