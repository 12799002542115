.action-configuration {
  .form-wrapper {
    margin-top: 0;

    .input-wrapper {
      margin-top: 12px;
      margin-bottom: 20px;
    }
  }

  .yellow-div {
    display: flex;
    padding: 20px;
    margin-bottom: 20px;

    background-color: #fcf1d2;
    border-radius: 4px;

    .text-wrapper {
      display: flex;
      align-items: center;

      p {
        display: inline-block;

        font-family: $font-roboto;
        font-size: 16px;

        border-bottom: 1px solid #000;
        border-bottom-style: dotted;
      }

      svg {
        padding: 2px;
        margin-top: 22px;
        margin-right: 10px;

        font-size: 20px;
        color: #f0b922;

        border: 2px solid #f0b922;
        border-radius: 100%;
      }
    }
  }

  .colorize-div {
    display: flex;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;

    .text-wrapper {
      display: flex;
      align-items: center;

      p {
        display: inline-block;

        font-family: $font-roboto;
        font-size: 16px;

        border-bottom: 1px solid #000;
        border-bottom-style: dotted;
      }

      svg {
        padding: 2px;
        margin-right: 10px;

        font-size: 20px;
        color: #f0b922;

        border: 2px solid #f0b922;
        border-radius: 100%;
      }
    }
  }

  // .css-2b097c-container {
  //   width: 100%;
  // }

  // .css-g1d714-ValueContainer {
  //   padding: 0 25px;

  //   font-size: 16px;
  // }

  // .css-1hb7zxy-IndicatorsContainer {
  //   display: none;
  // }

  // .css-b8ldur-Input {
  //   padding: 0;
  //   margin: 0;
  // }

  .react-select {
    &__control {
      border: 1px solid red !important;
    }
  }

  .toggler-wrapper {
    .title {
      font-size: 15px;
    }
  }
}
