.palette-wrapper {
  display: flex;

  &__colors {
    display: flex;
  }
  &__single {
  margin-right: 20px;
  }
  .picker-wrapper {
    position: relative;

    .btn-color{
      width: 46px;
      margin-right: 10px;
    }
  }
  .pallet-modal-wrapper {
    z-index: 1;
    position: absolute;
    top: 100%;
    left: 0%;
  }
}
