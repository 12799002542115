.text-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;

  .transparent-input {
    padding: 0;
    width: 120%;

    font-size: 16px;
    
    background: transparent;
    border: 0;
    border-bottom: 1px dotted black;
    grid-area: 1 / 1 / 2 / 2;
  }

  .auto-grow-input {
    display: inline-grid;
    align-items: center;
    justify-items: start,
  }

  span {
    grid-area: 1 / 1 / 2 / 2;
    visibility: hidden,
  }
}