.tab-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  background: $brand-color;
  border-radius: 4px;

  &__icon {
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;

    color: $brand-color;

    background: white;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &__name {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 30px;

    font: 700 14px $font-ptserif;
    color: white;
  }

  &__btn {
    display: flex;
    margin-right: 10px;
  }

  //For toggler
  .toggler-wrapper {
    margin-right: 15px;

    .toggler-button {
      border-radius: 4px;

      span {
        width: 45px;
        height: 36px;

        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &:nth-of-type(2) {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
}