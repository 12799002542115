.subscriptions {
  .table-wrapper {
    .table {
      background-color: $white;
      border: 1px solid $gray-9;
      border-radius: 4px;
    }

    .row-wrapper {
      display: flex;
      align-items: center;
      padding: 15px;

      border-bottom: 1px solid $gray-9;

      &:last-of-type {
        border: 0;
      }

      .column-wrapper {
        &:first-of-type {
          min-width: 20%;
        }

        &:not(:first-of-type) {
          min-width: 13.5%;
        }

        &:nth-of-type(4) {
          min-width: 18%;
        }

        &:last-of-type {
          display: flex;
          justify-content: flex-end;
          min-width: 8%;
        }

        &.is-id {
          text-transform: uppercase;
        }
      }
    }

    .table-heading {
      font-family: $font-ptserif;
      font-size: 14px;
      font-weight: bold;
      color: $brand-color;
    }

    .table-info {
      font-size: 14px;
      overflow-wrap: break-word;
    }

    svg {
      color: $brand-color;
    }
  }
}
