 .actions{
   .tab-header{
    padding-bottom: 0;
   }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;


  }

  .btn-white {
    padding: 10px;
    height: 36px;
  }
 }