.subscriptions-info {
  .subscriber-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    background-color: $brand-color;
    border-radius: 4px;
  }

  .subscriber-name {
    margin-bottom: 15px;

    font-family: $font-ptserif;
    font-weight: 600;
    font-size: 20px;
    color: $white;
  }

  .subscriber-id {
    font-size: 14px;
    color: $white;
  }

  .subscriber-status {
    padding: 10px 15px;

    font-size: 14px;
    font-weight: 600;
    color: $gray;
    text-transform: uppercase;

    background-color: $white;
    border-radius: 4px;
  }

  .subscription-info-heading {
    display: flex;
    margin: 20px 0;

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      min-height: 50px;
      min-width: 50px;

      background-color: $white;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      svg {
        color: $brand-color;
      }
    }

    .heading-wrapper {
      display: flex;
      align-items: center;
      padding-left: 20px;
      width: 100%;
      // width: 95%;

      font-family: $font-ptserif;
      color: $white;

      background-color: $brand-color;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .history-wrapper {
    position: relative;

    padding: 20px 50px;

    background-color: $white;
    border-radius: 4px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 30px;

      display: block;
      width: 3px;
      height: 100%;
      background-color: $brand-color;
    }

    .status-wrapper {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .status {
      position: relative;

      font-family: $font-ptserif;
      color: $brand-color;
      font-weight: 600;

      &:before {
        content: '';
        position: absolute;
        top: 6px;
        left: -25px;

        display: block;
        width: 14px;
        height: 14px;
        background-color: $brand-color;
        border-radius: 100%;
      }
    }
  }

  .accounts-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .account-holder {
      position: relative;

      display: flex;
      width: 100%;
    }

    .linked-account {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-right: 2.6%;
      margin-bottom: 20px;
      width: 23%;
      height: 150px;

      background-color: $white;
      border-radius: 4px;
      border: 0;

      &:nth-of-type(4) {
        margin-right: 0;
      }

      .title-wrapper {
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
        margin-bottom: 15px;

        cursor: pointer;

        svg {
          margin-left: 5px;
          margin-top: 2px;

          font-size: 20px;
          color: $brand-color;
        }
      }

      p, a {
        margin-bottom: 0;

        font-family: $font-ptserif;
        font-size: 18px;
        color: $brand-color;
        font-weight: 600;
      }
    }

    .dropdown-wrapper {
      position: absolute;
      top: 150px;
      // left: 0;
      right: -20%;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0;
      width: 232px;
      min-height: 125px;

      background-color: $white;
      border-radius: 4px;
      box-shadow: 0px 0px 10px #89898980;

      .list-wrapper {
        display: flex;
        flex-direction: column;

        .plan-link {
          margin-bottom: 10px;

          font-family: $font-ptsans;
          font-size: 14px;
          font-weight: normal;
          color: $black;
          text-decoration: underline;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .data-wrapper {
    display: flex;
    padding: 10px;

    background-color: $white;
    border-radius: 4px;

    p {
      line-height: 2.5;
    }

    &__left {
      margin-right: 50px;

      p {
        font-weight: 600;
      }
    }
  }
}