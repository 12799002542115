.layout-dark {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 140px);
  
  color: white;
  
  overflow: hidden;
  
  background-color: #1c0b19;
  
  .splode {
    position: absolute;
    top: -214px;
    left: -171px;
    z-index: 2;

    max-width: 100%;
    height: auto;
  }

  .main {
    position: relative;
    z-index: 3;
    flex-grow: 1;
  }
}

@include respond-to(md-down) {
  .layout-dark {
    min-height: calc(100vh - 100px);

    .splode {
      top: -343px;
      left: -332px;
    }
  }
}

@include respond-to (xs) {
  .layout-dark {
    .splode {
      top: -40px;
      left: -99px;
    }
  }
}
