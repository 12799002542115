footer {
  display: flex;
  align-items: center;
  height: 140px;

  background-color: $gray;

  .footer-wrapper {
    p, 
    a {
      font-size: 14px;
      color: $white;
    }

    a {
      margin-left: 4px;
    }
  }
}

@include respond-to(md-down) {
  footer {
    height: 100px;
  }
}