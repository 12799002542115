.dashboard {
  .breadcrumbs {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin: -25px -25px 25px -25px;

    background: #d3e5e3;

    a {
      font-size: 14px;
      color: #000000;
      text-decoration: underline;
    }

    svg {
      transform: translateY(0px);
      font-size: 14px;
      margin-right: 5px;
    }
  }
  .main-wrapper {
    display: flex;
  }

  .loader-wrapper {
    position: fixed;
    z-index: 3;

    margin: -25px; //overides padding of main content
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: calc(100% - 250px);

    background-color: rgba(128, 128, 128, 0.295);
  }

  &.dashboard-admin {
    display: flex;

    .loader-wrapper {
      margin: 0;
    }

    .main-content {
      padding: 0;
    }

    .main-wrapper {
      display: flex;
    }
  }

  &.impersonate {
    .main-content {
      min-height: calc(100vh - 69px);
      margin-top: 68px;
    }

    .proxy-notif {
      position: fixed;
      top: 0;
      z-index: 1;

      width: 100%;

      span,
      p {
        font-size: 16px;
        color: $gray-5;
        top: 0;
      }

      display: flex;
      padding: 23px 6px;
      justify-content: center;

      background-color: #ffdc9b;

      .notif-details {
        align-self: center;
      }

      button {
        position: absolute;
        left: 20px;

        display: flex;
        align-items: center;

        border: none;
        background-color: transparent;
        span {
          font-weight: bold;
        }
        svg {
          margin-right: 10px;
        }
      }
    }

    .sidebar-wrapper {
      left: 0;
      top: 69px;

      min-height: calc(100vh - 70px);
    }
  }

  &.page-get-started {
    .modal-main {
      width: 95%;
    }
  }

  &.email {
    .form-wrapper {
      &.v2 {
        .input-wrapper {
          label span {
            margin-right: 10px;
          }
        }
      }
    }

    .palette-wrapper {
      margin-left: 10px;
      flex-wrap: wrap;

      &__single {
        margin-right: 10px;
        margin-bottom: 10px;
      }

      .picker-wrapper {
        .btn-color {
          margin-right: 5px;
        }
      }
    }
  }

  .section-title {
    margin-bottom: 10px;
    color: $brand-color;
    font: normal normal bold 18px/23px PT Serif;
  }

  section {
    position: relative;

    margin-bottom: 20px;
    padding: 15px 20px;
    width: 100%;

    background-color: white;
    border-radius: 4px;

    &.no-bg {
      background: transparent;
      padding-left: 0;
      padding-right: 0;
    }

    &.steps {
      padding: 0;
    }
  }

  .banner {
    position: relative;

    background-color: $brand-color;

    .banner-image {
      position: absolute;
      right: 39px;
      top: -17px;
      height: 182px;
    }

    p {
      color: white;
      max-width: 85%;
    }

    .section-title {
      color: white;
    }
  }

  p,
  span {
    font-size: 14px;
    font-family: $font-ptsans;
    line-height: 23px;

    color: $seal-brown;
  }

  .sidebar-wrapper {
    // flex-grow: 1;
    // max-width: 16%;

    //Uncomment for floating sidebar
    flex-grow: 1;
    left: 0;
    top: 0;
    max-width: 250px;
    width: 100%;
    position: fixed;
  }

  .main-content {
    padding: 25px;

    margin-left: 250px;
    width: calc(100% - 250px);
    min-height: 100vh;

    background-color: $gray-4;
  }

  .content-wrapper {
    display: flex;
    align-items: center;

    .description {
      flex-grow: 1;
    }

    .tr {
      display: flex;

      .td:first-child {
        width: 100%;
        max-width: 200px;
      }
    }

    button {
      position: absolute;
      right: 17px;
      top: 50%;

      max-height: 42px;

      transform: translateY(-50%);
    }
  }

  .modal-main {
    max-height: 95%;
    overflow-y: scroll;
  }
}


@media (min-width: 1300px)  and (max-width: 1400px) {
  .dashboard {
    &.email {
      .preview-email {
        .content {
          top: 24%;
        }
      }
    }
  }
}

@media (min-width: 1200px)  and (max-width: 1299px) {
  .dashboard {
    &.email {
      .preview-email {
        .content {
          top: 20%;
        }
      }
    }
  }
}