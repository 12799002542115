.event-log {
  .table-wrapper {
    .table {
      background-color: $white;
      border: 1px solid $gray-9;
      border-radius: 4px;
    }

    .row-wrapper {
      display: flex;
      align-items: center;
      padding: 15px;

      border-bottom: 1px solid $gray-9;

      &:last-of-type {
        border: 0;
      }

      .column-wrapper {
        min-width: 25%;

        &:nth-of-type(2) {
          min-width: 20%;
        }

        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(6) {
          min-width: 10%;
        }

        &:nth-of-type(5) {
          min-width: 20%;
        }

        &:last-of-type {
          display: flex;
          justify-content: flex-end;
          min-width: 5%;
        }

        &.is-id {
          text-transform: uppercase;
        }
      }

      button {
        display: none;
        background-color: transparent;
        border: 0;
      }

      &.is-failed {
        .column-wrapper {
          &:nth-of-type(6) {
            p {
              color: red;
              font-weight: bold;
            }
          }
          button {
            display: block;
          }
        }
      }
    }

    .table-heading {
      font-family: $font-ptserif;
      font-size: 14px;
      font-weight: bold;
      color: $brand-color;
    }

    .table-info {
      font-size: 14px;
      overflow-wrap: break-word;
    }

    svg {
      color: $brand-color;
    }
  }

  .modal {
    .json-response-wrapper {
      padding: 30px;
      margin: 20px 0px;
      height: 500px;
      overflow-y: scroll;
      width: 100%;

      font-family: $font-ptmono;
      color: $white;
      
      background-color: #1b0b18;
      border-radius: 4px;

      span {
        font-family: $font-ptmono;
        color: $white;
      }
    }

    .btn-purple {
      width: 100%;
    }
  }
}